<!-- 忘记密码 -->
<template>
  <div>
    <Header />
    <div>
      <div class="autowidth main-body getpassword getpassword-index">
        <div class="password-title">
          找回密码
          <!-- <div class="step-box">
			<span class="step1 active">验证账号<i></i></span>
			<span class="step2">选择找回方式<i></i></span>
			<span class="step3">填写验证码<i></i></span>
			<span class="step4">重设密码</span>
		</div> -->
        </div>
        <div class="password-info">
          请输入您的登录账号，以验证账号是否存在进行密码重设
        </div>
        <div class="form">
          <!-- <div class="submit-box">
				<div class="row-item">
					<span>登录账号</span>
					<input type="text" name="username" placeholder="请输入注册邮箱/手机号" autocomplete="off" id="username" class="input input-300">
				</div>
				<div class="captcha-item clearfix">
					<div class="row-item ">
						<span>验证码</span>
						<input type="text" name="captcha" id="captcha" class="input captcha">
					</div>
					<img src="" class="show-captcha">
					<span class="refresh-btn"></span>
				</div>
				<div class="row-btn">
					<input type="button" value="验证账号" class="submit next-step">
				</div>
			</div> -->
          <el-form
            @submit.native.prevent
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="输入手机号："
              prop="phone"
              :label-width="formLabelWidth"
            >
              <el-input
                type="phone"
                v-model="phone"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="输入验证码：" :label-width="formLabelWidth">
              <!-- <input class="verification" v-model="ruleForm.code" type="text" /> -->
              <el-input
                type="phone"
                v-model="ruleForm.code"
                autocomplete="off"
              ></el-input>
              <button
                v-show="show"
                @click="sendcode()"
                style="margin-left: 50px"
                class="verificationbtn"
              >
                发送验证码
              </button>

              <button
                v-show="!show"
                :value="count"
                style="margin-left: 50px"
                class="verificationbtn"
              >
                {{ count }}
              </button>
            </el-form-item>

            <el-form-item
              label="输入新密码："
              prop="pass"
              :label-width="formLabelWidth"
            >
              <el-input
                type="password"
                v-model="ruleForm.pass"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="确认密码:"
              prop="checkPass"
              :label-width="formLabelWidth"
            >
              <el-input
                type="password"
                v-model="ruleForm.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              style="margin-left: 325px"
              prop="checkPass"
              :label-width="formLabelWidth"
            >
              <button class="sendcode" @click="check">完成</button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import { getCode, forgetpwd, getLogin } from "@/api/all.js";
import { setToken } from "@/utils/auth.js";
export default {
  components: { Header },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    // var validatePass3 = (rule, value, callback) => {
    //     if (value === '') {
    //       callback(new Error('请输入手机号'));
    //     } else {
    //       if (this.phone !== '') {
    //         this.$refs.phone.validateField('phone');
    //       }
    //       callback();
    //     }
    //   };
    return {
      count: "",
      show: true,
      formLabelWidth: "350px",
      phone: null,
      ruleForm: {
        pass: "",
        checkPass: "",
        code: "",
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    check() {
      if (this.ruleForm.pass == this.ruleForm.checkPass) {
        forgetpwd({
          phone: this.phone,
          code: this.ruleForm.code,
          password: this.ruleForm.checkPass,
        }).then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.$message({
              message: "修改密码成功",
              type: "success",
            });
            this.$router.push("/login");
          } else {
            this.his.$message.error("登录失败");
          }
        });
      } else {
        this.$message.error("两次输入密码不一致");
      }
    },
    sendcode() {
      getCode({ phone: this.phone }).then((res) => {
        if (res.code == 200) {
          if (res.code == 200) {
            // 自定义时间 TIME_COUNT 默认 60
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
          // (this.dialogFormVisible = true), (this.dialogTableVisible = true);
          console.log(res);
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../../assets/static/template/Home/Zkeys/PC/Static/css/module/getPassword/password.css";
</style>

<style scoped>
.el-form {
  padding-left: 50px !important;
}

.el-dialog {
  width: 800px !important;
  height: 477px !important;
}

.stepsbox {
  margin: 0 auto;
  width: 741px;
  height: 75px;
}

.steps {
  position: relative;
  margin-bottom: 30px;
  counter-reset: step;
  /*创建步骤数字计数器*/
  z-index: 5;
}

/*步骤描述*/
.steps li {
  list-style-type: none;
  font-size: 12px;
  text-align: center;
  width: 23%;
  position: relative;
  float: left;
}

/*步骤数字*/
.steps li:before {
  display: block;
  content: counter(step);
  /*设定计数器内容*/
  counter-increment: step;
  /*计数器值递增*/
  width: 32px;
  height: 32px;
  background-color: #b60005;
  line-height: 32px;
  border-radius: 32px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0 auto 8px auto;
}

/*连接线*/
.steps li ~ li:after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #b60005;
  position: absolute;
  left: -50%;
  top: 15px;
  z-index: -1;
  /*放置在数字后面*/
}

/*将当前/完成步骤之前的数字及连接线变绿*/
.steps li.active:before,
.steps li.active:after {
  background-color: #b60005;
}

/*将当前/完成步骤之后的数字及连接线变灰*/
.steps li.active ~ li:before,
.steps li.active ~ li:after {
  background-color: #777;
}

.verification {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  width: 288px;
  height: 36px;
  margin-right: 16px;
}

.verification2 {
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  width: 350px;
  height: 36px;
  margin-right: 16px;
}

.verificationbtn {
  border: 1px solid #b60005;
  color: #b60005;
  background-color: #ffffff;
  border-radius: 2px;
  width: 107px;
  height: 36px;
}

.sendcode {
  width: 107px;
  height: 36px;
  background-color: #b60005;
  color: #ffffff;
  border-radius: 2px;
}

.notice-list > li > p {
  color: #141414;
  padding-bottom: 22pt;
}

.notice-list > li > p > span {
  color: #141414;
}

.notice-list > li > button {
  background-color: #b60005;
  width: 107px;
  height: 36px;
  border-radius: 2pt;
  color: #ffffff;
}

.page-notice {
  overflow: hidden;
}

.notice-side ul {
  margin: 0 0;
  overflow: hidden;
}

.notice-body {
  height: 811px;
}

.span {
  border: 0px;
}

.notice-list li {
  padding: 0px !important;
  border-bottom: 0px;
  overflow: hidden;
}

p {
  color: #999999 !important;
}
/deep/ .el-input__inner:focus {
  border-color: #a72118;
}
/deep/ .el-input {
  width: 258px;
}
</style>
